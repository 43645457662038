<template>
    <b-row class="match-height">
        <b-col lg="4" md="4">
          <b-card
    no-body
    class="card-employee-task"
  >
    <b-card-header>
      <b-card-title>就诊患者</b-card-title>
      <b-card-title class="text-primary">
        {{today}}
      </b-card-title>
    </b-card-header>

    <!-- body -->
    <b-card-body class="chaochuyingcang1">
      <div
        v-for="(employee,index) in employeeData"
        :key="employee.userFullName"
        class="employee-task d-flex justify-content-between align-items-center p10"
        @click="checkactive(index)"
        :class="activeIndex === index?'changactive' : ''"
      >
        <b-media no-body>
          <b-media-aside class="mr-75">
            <b-avatar
              rounded
              size="42"
              :src="employee.avatar"
            />
          </b-media-aside>
          <b-media-body class="my-auto">
            <div class="mb-0" style="font-weight: 700;">
              {{ employee.userFullName }}
            </div>
            <small>{{ employee.designation }}</small>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <b-button
              block
              :variant="activeIndex === index?'success' : 'primary'"
            >
              接诊
            </b-button>
        </div>
      </div>
    </b-card-body>
  </b-card>
            <!-- <CardAdvanceEmployeewait /> -->
            <b-card
    no-body
    class="card-employee-task"
  >
    <b-card-header>
      <b-card-title>待签到</b-card-title>
      <feather-icon
        icon="MoreVerticalIcon"
        size="18"
        class="cursor-pointer"
      />
    </b-card-header>

    <!-- body -->
    <b-card-body class="chaochuyingcang2">
      <div
        v-for="(employee,index) in employeeData"
        :key="employee.userFullName"
        class="employee-task d-flex justify-content-between align-items-center"
      >
        <b-media no-body>
          <b-media-aside class="mr-75">
            <b-avatar
              rounded
              size="42"
              :src="employee.avatar"
            />
          </b-media-aside>
          <b-media-body class="my-auto">
            <h6 class="mb-0">
              {{ employee.userFullName }}
            </h6>
            <small>{{ employee.designation }}</small>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <small class="text-muted mr-75">{{ employee.duration }}</small>
        </div>
      </div>
    </b-card-body>
  </b-card>
        </b-col>
        <b-col lg="4" md="7">
            <!-- <CardAdvancePayAmountinfo /> -->
        </b-col>
        <b-col lg="4" md="6">
            <!-- <card-advance-pay-amount /> -->
        </b-col>
    </b-row>
  </template>
  
  <script>
  import {BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BButton} from 'bootstrap-vue'
  
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardTitle,
      BCardHeader,
      BCardBody,
      BMedia,
      BMediaAside,
      BMediaBody,
      BAvatar,
      BButton
    },
    data() {
    return {
      activeIndex: null,
      today:'',
      employeeData: [
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
          userFullName: '吴先生',
          designation: '13480874300',
          duration: '9hr 20m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
          userFullName: 'Louisa Norton',
          designation: 'UI Designer',
          duration: '4hr 17m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
          userFullName: 'Jayden Duncan',
          designation: 'Java Developer',
          duration: '12hr 8m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
          userFullName: 'Cynthia Howell',
          designation: 'Anguler Developer',
          duration: '3hr 19m',
        },
      ],
    }
  },
  created() {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    this.today = year+ '年' + month + '月' + strDate + '日'
  },
  methods: {
    checkactive(index) {
      this.activeIndex = index
    }
  }
}
</script>

<style>
.chaochuyingcang1{
  height: 252px;
  overflow: hidden;
  overflow-y: visible;
}
.p10{
  padding: 10px;
}
.changactive{
  background-color: #7367f0 !important;
  border-radius: 10px;
  color: white !important;
}
</style>
  